import { supabaseCeneClient } from '../supabase/supabase';

export async function signUpWithProfile(
  email: string,
  password: string,
  first_name: string,
  last_name: string,
  gender: string,
  birthdate: string | null,
  phone_number: string
) {
  let { data: userData } = await supabaseCeneClient.auth.getUser();

  if (!userData?.user) {
    const { data, error: signUpError } = await supabaseCeneClient.auth.signUp({
      email,
      password,
    });

    if (signUpError) throw signUpError;

    userData = data;
  }

  const { data: profile } = await supabaseCeneClient
    .from('customers')
    .select('*')
    .eq('user_id', userData?.user?.id)
    .single()
    .throwOnError();

  if (profile) {
    const { error: updatePasswordError } =
      await supabaseCeneClient.auth.updateUser({
        password,
      });

    if (updatePasswordError) throw updatePasswordError;
  } else {
    const { error: insertUserError } = await supabaseCeneClient
      .from('customers')
      .insert([
        {
          user_id: userData?.user?.id,
          first_name: first_name,
          last_name: last_name,
          gender: gender,
          birthdate: birthdate || null,
          phone_number: phone_number,
          onboarding_finished: true,
        },
      ]);

    if (insertUserError) throw insertUserError;
  }

  return userData;
}
