import FaviconManager from '../../../../components/faviconManager/faviconManager';
import Steps from '../../../../components/steps/steps';
import { ROUTE_AUTH } from '../../../../constants/routes';
import { usePreventUnload } from '../../../../hooks/usePreventUpload';
import { useSupabase } from '../../../../providers/supabase';
import {
  PasswordFormWithoutEmailSchema,
  passwordFormWithoutEmailSchema,
} from '../../../../schemas/registerSchema';
import { supabaseCenePlusClient } from '../../../../supabase/supabase';
import { GenrePreferencesForm } from '../../genrePreferencesStep/cenePlus/genrePreferences';
import { InterestPreferencesForm } from '../../interestPreferencesStep/cenePlus/interestPreferences';
import { Notifications } from '../../notificationStep/notificationForm';
import { PasswordForm } from '../../passwordStep/cenePlus/passwordForm';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface RegisterFormPartTwoProps {
  onLoading: () => void;
}

export const RegisterFormPartTwo = ({
  onLoading,
}: RegisterFormPartTwoProps) => {
  const [activeStep, setStep] = useState(1);
  const navigate = useNavigate();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [onboardingFinished, setOnboardingFinished] = useState(false);
  const { session } = useSupabase();

  usePreventUnload(isFormDirty);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!session?.user?.id) return;

      const { data, error } = await supabaseCenePlusClient
        .from('customers')
        .select('onboarding_finished')
        .eq('user_id', session.user.id)
        .single();

      if (!error && data) {
        setOnboardingFinished(data.onboarding_finished);
      }
    };

    fetchUserData();
  }, [session]);

  const onBackStep = useCallback(() => {
    setStep((prevStep) => {
      if (prevStep === 1) {
        navigate(ROUTE_AUTH);
        return prevStep;
      }
      return prevStep - 1;
    });
  }, []);

  const nextStep = useCallback(() => setStep((prev) => prev + 1), []);

  const validationSchemas = [passwordFormWithoutEmailSchema];

  const isSkip = activeStep === 2 || activeStep === 3 || activeStep === 4;

  const onSubmit = async (values: PasswordFormWithoutEmailSchema) => {
    try {
      const { data: updatedUser } =
        await supabaseCenePlusClient.auth.updateUser({
          password: values.password,
        });

      const { error } = await supabaseCenePlusClient
        .from('customers')
        .update([
          {
            interests: values.interestGenres,
            genres: values.musicGenres,
            notifications: values.notifications,
            onboarding_finished: true,
          },
        ])
        .eq('user_id', updatedUser?.user?.id);

      if (error) return alert(error.message);

      setOnboardingFinished(true);
      onLoading();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
          agreement: false,
          musicGenres: [],
          interestGenres: [],
          notifications: [],
          onboarding_finished: onboardingFinished,
        }}
        validationSchema={
          validationSchemas[activeStep - 1] || validationSchemas[0]
        }
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, dirty }) => {
          useEffect(() => {
            setIsFormDirty(dirty);
          }, [dirty]);

          useEffect(() => {
            setFieldValue('onboarding_finished', onboardingFinished);
          }, [onboardingFinished]);

          return (
            <Steps
              width={168}
              size="px"
              isPremium={true}
              activeStep={activeStep}
              onBack={onBackStep}
              onSkip={() => {
                if (activeStep === 2) setFieldValue('musicGenres', []);
                if (activeStep === 3) setFieldValue('interestGenres', []);
                if (activeStep === 4) {
                  return onSubmit({ ...values, notifications: [] });
                }

                nextStep();
              }}
              canSkip={isSkip}
              hideBackButton={activeStep === 1}
            >
              <PasswordForm onNext={nextStep} />
              <GenrePreferencesForm onNext={nextStep} />
              <InterestPreferencesForm onNext={nextStep} />
              <Notifications onNext={() => onSubmit(values)} isPremium={true} />
            </Steps>
          );
        }}
      </Formik>
      <FaviconManager isCenePlus />
    </>
  );
};
